

/*

ref links

*/

.three-wide-lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .ref-card {
    background-color: #3e3e42;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 20px 30px 20px;
    border-radius: 12px;
    border: 1px solid black;
    filter: drop-shadow(2px 2px 5px black);
    height: 22rem;;
    width: 20rem;
    min-width: 20rem;
    padding: .9rem;
  }

  .ref-card-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95%;
  }


  .ref-button {
    background-color: #3e3e42;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #1ccbe6;
    width: 8rem;
    height: 4rem;
    margin: .2rem 0 1rem 0;
    filter: drop-shadow(1px 2px 4px #1ccbe6);
    transition: background-color .8s;
  }

  .ref-button > a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 99%;
    height: 99%;
  }

  .ref-button:hover {
    background-color: #252526;
    border: 1px solid #252526;
  }

  .ref-button:active {
    filter: drop-shadow(0.1px 0.1px 1px #1ccbe6);
  }

  @media screen and (max-width: 760px) {
    .ref-card {
      height: auto;
      margin-bottom: 10px;
    }
    .socials {
      align-items: center;
      flex-direction: column;
      margin: 0 10px 20px 0;
    }
  }
  @media screen and (min-width: 1500px) {
    .three-wide-lists {
      max-width: 1400px;
    }
  }
