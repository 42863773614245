.cautioncoin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.cautionh1 {
    font-size: 90px;
    margin: 20px;
}

.cautionh2 {
    font-size: 38px;
}

.text-block-1 {
    width: 800px;
}

.text-block-2 {
    width: 800px;
}

.a-tag {
    color: #7eecff;
    text-decoration: underline;
    transition: color 1s;
}

.a-tag:hover {
    color: #24bed6;
    text-decoration: underline;
}

.whitepaper-button {
    color: white;
  display: flex;
  justify-self: flex-end;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #3e3e42;
  margin: 4px 2px;
  padding: 8px 2px;
  border-radius: 12px;
  width: 140px;
  border: 1px solid #1ccbe6;
  filter: drop-shadow(.5px .5px 1px #1ccbe6);
  transition: background-color .8s;
}

.whitepaper-button:hover {
    background-color: #252526;
    border: 1px solid #252526;
  }


@media screen and (max-width: 760px) {
    .cautioncoin {
        width: 350px;
        margin: 0 auto;
        font-size: 16px;
    }
    .cautionh1 {
        font-size: 40px;
    }
    .cautionh2 {
        font-size: 22px;
        text-align: center;
    }
    .text-block-1 {
        width: 310px;
    }
    .text-block-2 {
        width: 300px;
    }
  }

@media screen and (max-width: 350px) {
    .cautioncoin {
        width: 300px;
        margin: 0 auto;
        font-size: 12px;
    }
    .cautionh1 {
        font-size: 30px;
    }
    .cautionh2 {
        font-size: 18px;
        text-align: center;
    }
    .text-block-1 {
        width: 220px;
    }
    .text-block-2 {
        width: 220px;
    }
  }
