
  /*

  home

  */
  .caution-pic {
    width: 210px;
    height: 210px;
  }

  .lchammer {
    border-radius: 15px;
    filter: drop-shadow(3px 3px 4px #000);
    margin-bottom: 20px;
    width: 50%;
    min-width: 360px;
    max-width: 1000px;
  }
  .body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  h1 {
    font-size: 3rem;
  }

  h2 {
    margin: 10px 0 10px 0;
  }

  h3 {
    margin: 3px 0 2px 0;
  }

  a {
    text-decoration: none;
    color: #FFFFFF;
  }

  .two-across {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    max-width: 1800px;
    margin-top: 1rem;
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #1ccbe6;
    background-color: 	#3e3e42;
    height: 20rem;
    width: 30rem;
    min-width: 18rem;
    border-radius: 10px;
    filter: drop-shadow(4px 4px 10px #1ccbe6);
    transition: background-color .8s;
  }

  .block:hover {
    background-color: #252526;
    border: 1px solid #1ccbe6;
  }

  .block:active {
    filter: drop-shadow(1px 1px 3px #1ccbe6);
  }

  .merch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
  }

  .ref-links {
    font-size: 5rem;
  }

  @media screen and (max-width: 1000px) {
    .two-across {
      width: 60%;
      gap: 30px;
    }
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  @media screen and (max-width: 500px) {
    .two-across {
      display: none;
    }
    .block {
      height: 14rem;
    }
  }
