/*

nav

*/
.login-modal {
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  z-index: 2;
  top: 90px;
  right: 10px;
  transition: right 1s;
  background-color: #3e3e42;
  opacity: .95;
  width: 220px;
}

.hidden {
  right: -400px;
}

.fa-user-circle {
  font-size: 24px;
}


.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px auto;
    padding: 0 60px;
    overflow: hidden;
  }
  .nav-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .caution-pic-nav {
    height: 50px;
    justify-self: flex-start;
    margin-left: 30px;
  }
  .nav-button {
  color: white;
  display: flex;
  justify-self: flex-end;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #3e3e42;
  margin: 4px 2px;
  padding: 8px 2px;
  border-radius: 12px;
  width: 90px;
  border: 1px solid #1ccbe6;
  filter: drop-shadow(.5px .5px 1px #1ccbe6);
  transition: background-color .8s;
  }

  .nav-button:hover {
    background-color: #252526;
    border: 1px solid #252526;
  }

  @media screen and (max-width: 700px) {
    .nav {
      padding: 0 5px;
    }
    .nav-button {
      width: 100px;
    }
    .caution-pic-nav {
      display: none;
    }
  }
