* {
  font-family: Arial, Helvetica, sans-serif;
}
body {
  /* display: flex; */
  /* justify-content: space-around;
  align-items: center; */
  margin: 0;
  background-color: #2F2F2F;
  color:	whitesmoke;
}
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  body {
    height: 100vh;
  }
}
