.vault {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-gap: 20px; */
    margin-top: 50px;
}

.filters {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* grid-column: span 4; */
    margin: 30px auto;
}

.filter-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 260px;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.nothing-filtered {
    grid-column: span 4;
    font-size: 62px;
    margin: 40px auto;
}

.nothing-filtered-small {
    grid-column: span 4;
    font-size: 28px;
    margin: 40px auto;
}

.current-filter {
    border: #1ccbe6;
    box-shadow: 1px 1px 9px 5px #1ccbe6;
    /* filter: drop-shadow(1px 2px 4px #1ccbe6); */
}

.button {
    color: whitesmoke;
    background-color:  #3e3e42;
    width: 76px;
    height: 30px;
}

@media screen and (max-width: 1380px) {
    .vault {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1000px) {
    .vault {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 1100px) {
    .filters {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px) {
    .vault {
        grid-template-columns: 1fr;
    }
    .filters {
        grid-template-columns: 1fr;
    }
  }
